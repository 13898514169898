// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/@home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ingredients-anchors-js": () => import("./../../../src/pages/ingredients/anchors.js" /* webpackChunkName: "component---src-pages-ingredients-anchors-js" */),
  "component---src-pages-ingredients-clients-js": () => import("./../../../src/pages/ingredients/clients.js" /* webpackChunkName: "component---src-pages-ingredients-clients-js" */),
  "component---src-pages-ingredients-contact-js": () => import("./../../../src/pages/ingredients/contact.js" /* webpackChunkName: "component---src-pages-ingredients-contact-js" */),
  "component---src-pages-ingredients-corporate-js": () => import("./../../../src/pages/ingredients/@corporate.js" /* webpackChunkName: "component---src-pages-ingredients-corporate-js" */),
  "component---src-pages-ingredients-footer-js": () => import("./../../../src/pages/ingredients/footer.js" /* webpackChunkName: "component---src-pages-ingredients-footer-js" */),
  "component---src-pages-ingredients-identity-js": () => import("./../../../src/pages/ingredients/identity.js" /* webpackChunkName: "component---src-pages-ingredients-identity-js" */),
  "component---src-pages-ingredients-laboratories-js": () => import("./../../../src/pages/ingredients/@laboratories.js" /* webpackChunkName: "component---src-pages-ingredients-laboratories-js" */),
  "component---src-pages-ingredients-lifestyle-js": () => import("./../../../src/pages/ingredients/@lifestyle.js" /* webpackChunkName: "component---src-pages-ingredients-lifestyle-js" */),
  "component---src-pages-ingredients-location-js": () => import("./../../../src/pages/ingredients/location.js" /* webpackChunkName: "component---src-pages-ingredients-location-js" */),
  "component---src-pages-ingredients-retail-js": () => import("./../../../src/pages/ingredients/@retail.js" /* webpackChunkName: "component---src-pages-ingredients-retail-js" */),
  "component---src-pages-ingredients-team-js": () => import("./../../../src/pages/ingredients/team.js" /* webpackChunkName: "component---src-pages-ingredients-team-js" */),
  "component---src-pages-nav-corporate-nav-js": () => import("./../../../src/pages/nav/@corporate_nav.js" /* webpackChunkName: "component---src-pages-nav-corporate-nav-js" */),
  "component---src-pages-nav-laboratories-nav-js": () => import("./../../../src/pages/nav/@laboratories_nav.js" /* webpackChunkName: "component---src-pages-nav-laboratories-nav-js" */),
  "component---src-pages-nav-lifestyle-nav-js": () => import("./../../../src/pages/nav/@lifestyle_nav.js" /* webpackChunkName: "component---src-pages-nav-lifestyle-nav-js" */),
  "component---src-pages-nav-retail-nav-js": () => import("./../../../src/pages/nav/@retail_nav.js" /* webpackChunkName: "component---src-pages-nav-retail-nav-js" */),
  "component---src-pages-single-corporate-single-js": () => import("./../../../src/pages/single/@corporate_single.js" /* webpackChunkName: "component---src-pages-single-corporate-single-js" */),
  "component---src-pages-single-laboratories-single-js": () => import("./../../../src/pages/single/@laboratories_single.js" /* webpackChunkName: "component---src-pages-single-laboratories-single-js" */),
  "component---src-pages-single-lifestyle-single-js": () => import("./../../../src/pages/single/@lifestyle_single.js" /* webpackChunkName: "component---src-pages-single-lifestyle-single-js" */),
  "component---src-pages-single-retail-single-js": () => import("./../../../src/pages/single/@retail_single.js" /* webpackChunkName: "component---src-pages-single-retail-single-js" */)
}

